<template>
  <div>
    <!-- 工商入户安检表具信息 -->
    <div class="content1">
      <div class="all">
        <div class="table" >
          <div style="display:flex;margin-top:20px">
            <el-button  class="SearchBtn" size="small" @click="backtrack">返回</el-button>
          </div>
          <div class="headline"><span></span>表具信息</div>
          <div style="display:flex;align-items:center;">
            <el-button  size="mini" @click="del" type="text"
              style="border: 1px solid #F56C6C;color: #F52424;padding:0 14px;height:30px" plain>批量删除</el-button>
            <el-button size="mini"  @click="add" type="text"
                       :disabled="form.buildings.length>9"
              style="border: 1px solid #1082FF;color: #1082FF;padding:0 14px;height:30px" plain>新增表具</el-button>
              <span style="color: #9A9CA1;font-size: 14px;">（最多可一次性添加10个表具）</span>
          </div>
          <el-form
          ref="ruleForm"
            class="inline:false"
            label-position="right"
            :model="form"
            label-width="100px"
          >
          <!--数据表格-->
          <el-table
              ref="tableList"
              :data="form.buildings"
              style="width: 100%; margin: 15px 0"
              border
              height="calc(100vh - 430px)"
              :stripe="true"
               @selection-change="handleSelectionChange"
            >
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column type="index" label="序号" width="70" align="center"  :index="((searchBox.current-1)*searchBox.size)+1"> </el-table-column>
              <el-table-column
                prop="aaa"
                label="表编号"
                align="center"
                show-overflow-tooltip
                :render-header="addRedStar"
              >
                <template slot-scope="scope">
                  <el-form-item
                    label=""
                    label-width="1"
                    :prop="'buildings.' + scope.$index + '.sheetSteel'"
                    :rules="[
                      { required: true, message: '请输入表编号', trigger: 'blur' },
                      { min: 1, max: 80, message: '长度最多80个字符', trigger: 'blur' }
                    ]"
                  >
                  <el-input
                      size="mini"
                      placeholder="请输入内容"
                      v-model="scope.row.sheetSteel"
                      maxlength="80"
                  >
                  </el-input>
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column
                prop="addressAttr"
                label="燃气表类型"
                align="center"
                show-overflow-tooltip
                :render-header="addRedStar"
              >
                <template slot-scope="scope">
                  <el-form-item
                    label=""
                    label-width="1"
                    :prop="'buildings.' + scope.$index + '.tableType'"
                    :rules="[
                      { required: true, message: '请输入表编号', trigger: 'blur' },
                      { min: 1, max: 80, message: '长度最多80个字符', trigger: 'blur' }
                    ]"
                  >
                  <el-select v-model="scope.row.tableType" placeholder="请选择" filterable size="mini">
                    <el-option
                        v-for="(item, index) in tableTypeList"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                  </el-select>
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column
                prop="companyId"
                label="表向"
                size="mini"
                align="center"
                show-overflow-tooltip
                :render-header="addRedStar"
              >
                <template slot-scope="scope">
                  <el-form-item
                    label=""
                    label-width="1"
                    :prop="'buildings.' + scope.$index + '.tableDirection'"
                    :rules="[
                      { required: true, message: '请输入表编号', trigger: 'blur' },
                      { min: 1, max: 80, message: '长度最多80个字符', trigger: 'blur' }
                    ]"
                  >
                  <el-select size="mini" v-model="scope.row.tableDirection" filterable placeholder="请选择" >
                    <el-option
                        v-for="(item, index) in tableDirectionList"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                  </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                prop="floorType"
                label="燃气表厂家"
                align="center"
                show-overflow-tooltip
                :render-header="addRedStar"
              >
                <template slot-scope="scope">
                  <el-form-item
                    label=""
                    label-width="1"
                    :prop="'buildings.' + scope.$index + '.tableVender'"
                    :rules="[
                      { required: true, message: '请输入表编号', trigger: 'blur' },
                      { min: 1, max: 80, message: '长度最多80个字符', trigger: 'blur' }
                    ]"
                  >
                  <el-select size="mini" v-model="scope.row.tableVender" filterable placeholder="请选择" >
                    <el-option
                        v-for="(item, index) in tableVenderList"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                  </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                prop="addressName"
                label="表封号"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-input
                      size="mini"
                      placeholder="请输入内容"
                      v-model="scope.row.tableTitles"
                      maxlength="80"
                  >
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="addressName"
                label="所接炉具"
                align="center"
                show-overflow-tooltip
                :render-header="addRedStar"
              >
                <template slot-scope="scope">
                  <el-form-item
                    label=""
                    label-width="1"
                    :prop="'buildings.' + scope.$index + '.receiveStove'"
                    :rules="[
                      { required: true, message: '请输入表编号', trigger: 'blur' },
                      { min: 1, max: 80, message: '长度最多80个字符', trigger: 'blur' }
                    ]"
                  >
                  <el-input
                      size="mini"
                      placeholder="请输入内容"
                      v-model="scope.row.receiveStove"
                      maxlength="80"
                  >
                  </el-input>
                  </el-form-item>
                </template>
              </el-table-column>
            <el-table-column
                prop="aaa"
                label="开户日期"
                align="center"
                width="200"
            >
              <template slot-scope="scope">
                <el-date-picker
                    size="mini"
                    v-model="scope.row.openDate"
                    type="datetime"
                    style="width: 170px"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="选择日期" >
                </el-date-picker>
              </template>
            </el-table-column>
              <!-- <el-table-column
                prop="addressName"
                label="对接系统"
                align="center"
                show-overflow-tooltip
                :render-header="addRedStar"
                width="200"
              >
                <template slot-scope="scope">
                  <el-radio-group v-model="scope.row.tableSystem">
                    <el-radio :label="1">卡表系统</el-radio>
                    <el-radio :label="2">营收系统</el-radio>
                  </el-radio-group>
                </template>
              </el-table-column>
              <el-table-column
                prop="addressName"
                label="识别号"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-input
                      size="mini"
                      placeholder="请输入对应识别号"
                      v-model="scope.row.identifyNumber"
                      maxlength="80"
                  >
                  </el-input>
                </template>
              </el-table-column> -->
              <el-table-column
                prop="addressName"
                label="备注"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="{row}">
                  <el-input
                      size="mini"
                      placeholder="请输入备注"
                      v-model="row.remark"
                      maxlength="80"
                  >
                  </el-input>
                </template>
              </el-table-column>
            </el-table>
            </el-form>
          <el-button type="primary" @click="submit">确定</el-button>
        </div>
      </div>
    </div>
    
  </div>
</template>


<script>
import  {tableWithBatchInsert} from '../../../RequestPort/intranet/index'
import {selectDictListByPid} from "../../../RequestPort/business/userFile";
export default {
  name: "lockout",
  data(){
    return{
      // 查询条件
      searchBox:{
        current:1,
        size: 100,
        fileSource:1
      },
      total:0,
      // 查询
      typeList:[
        {
          name:'启用',
          id:1
        },
        {
          name:'禁用',
          id:0
        }
      ],
      tableTypeList:[],
      tableDirectionList:[],
      tableVenderList:[],
      // 全局计数
      number:0,
      // 列表数据
      form:{
      buildings: [
          {
            num:0,
            sheetSteel:'',
            tableType:'',
            tableDirection:'',
            tableVender:'',
            tableTitles:'',
            receiveStove:'',
            identifyNumber:'',
            // tableSystem:1,
            openDate:''
          }
        ],
      },
      userId:0,
      rule:{
         workId:{ required: true, message: "请选择工程名称", trigger: "change" }
      },
      // 暂存复选
      selection:[]
    }
  },
  mounted() {
    if(this.$route.query.id){
      this.userId = this.$route.query.id
      this.form.buildings[0].userId = this.$route.query.id
    }
    this.getOption()
  },
  methods:{
    submit(){
      if(this.form.buildings.length>0){
      //   let errStr = ''
      //   this.form.buildings.forEach((item,index)=>{
      //     item.userId = this.$route.query.id
      //     let num = index+1
      //     if(item.sheetSteel==''){
      //       return errStr ='第'+num+'行表编号不能为空！'
      //     }
      //     if(item.openDate==''){
      //       return errStr ='第'+num+'行开户日期不能为空！'
      //     }
      //     else if(item.tableType==''){
      //       return errStr ='第'+num+'行燃气表类型不能为空！'
      //     }
      //     else if(item.tableDirection==''){
      //       return errStr ='第'+num+'行表向不能为空！'
      //     }
      //     else if(item.tableVender==''){
      //       return errStr ='第'+num+'行燃气表厂家不能为空！'
      //     }
      //     else if(item.receiveStove==''){
      //       return errStr ='第'+num+'行所接炉具不能为空！'
      //     }
      //   })
      //   if(errStr!=''){
      //     this.$message.error(errStr)
      //   }else {
        this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          tableWithBatchInsert(this.form.buildings).then(res=>{
            if(res.code===200){
              this.$router.back(-1)
              this.$message.success(res.data)
            }else {
              this.$message.error(res.data)
            }
          })
          } 
      })
      //   }
      }else {
        this.$message.error('至少保留一条数据')
      }

    },
    getOption(){
      selectDictListByPid({pid: 707}).then(res => {
        this.tableDirectionList = res.data
      })
      selectDictListByPid({pid: 702}).then(res => {
        this.tableTypeList = res.data
      })
      selectDictListByPid({pid: 710}).then(res => {
        this.tableVenderList = res.data
      })
    },
    /**
     * 必填的星号
     */
    addRedStar(h,{ column}) {
      return [h('span',{style:'color:red'},'*'),h('span',' '+ column.label)]
    },
    /***
     * 复选功能
     */
    handleSelectionChange(val){
      this.selection = val
    },
    /**
     * 返回
     */
    backtrack(){
      this.$router.back(-1)
    },
    /**
     * 批量删除
     */
    del(){
      if(this.selection.length<1){
        return this.$message.error('请选择要删除的数据')
      }else {
        this.selection.forEach(el => {
          this.form.buildings.forEach((item,index) => {
            if(item.num == el.num) {
              this.form.buildings.splice(index,1)
            }
          })
        })
      }
    },

    // 添加
    add(){
      if(this.form.buildings.length<10) {
        this.number+=1
        this.form.buildings.push(
            {
              num:this.number,
              userId:this.userId,
              sheetSteel:'',
              tableType:'',
              tableDirection:'',
              tableVender:'',
              tableTitles:'',
              receiveStove:'',
              identifyNumber:'',
              // tableSystem:1,
              openDate:''
            })
      } else {
        this.$message.error('最多可一次性添加10个表具')
      }
    },
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-upload--picture-card{width:100px;height:100px;line-height:100px}
::v-deep .el-upload-list--picture-card .el-upload-list__item{width:100px;height:100px;line-height:100px}
.detil-code{
  padding-left: 10px;
  font-size: 16px;
  height: 26px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before{
  content: '';
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007AFF;
  top: 0;
  left: 0;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.SearchBtn{
  margin-left: 10px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
  // 标题
  .headline{
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #303133;
    display: flex;
    align-items:center;
    margin-top: 35px;
    margin-bottom: 20px;
    span{
      display: block;
      width: 3px;
      height: 16px;
      background: #1082FF;
      margin-right: 9px;
    }
  }
}
.el-radio{
  margin-right:0;
}
.el-form-item{
  margin-bottom:0;
}
</style>