var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content1"},[_c('div',{staticClass:"all"},[_c('div',{staticClass:"table"},[_c('div',{staticStyle:{"display":"flex","margin-top":"20px"}},[_c('el-button',{staticClass:"SearchBtn",attrs:{"size":"small"},on:{"click":_vm.backtrack}},[_vm._v("返回")])],1),_vm._m(0),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('el-button',{staticStyle:{"border":"1px solid #F56C6C","color":"#F52424","padding":"0 14px","height":"30px"},attrs:{"size":"mini","type":"text","plain":""},on:{"click":_vm.del}},[_vm._v("批量删除")]),_c('el-button',{staticStyle:{"border":"1px solid #1082FF","color":"#1082FF","padding":"0 14px","height":"30px"},attrs:{"size":"mini","type":"text","disabled":_vm.form.buildings.length>9,"plain":""},on:{"click":_vm.add}},[_vm._v("新增表具")]),_c('span',{staticStyle:{"color":"#9A9CA1","font-size":"14px"}},[_vm._v("（最多可一次性添加10个表具）")])],1),_c('el-form',{ref:"ruleForm",staticClass:"inline:false",attrs:{"label-position":"right","model":_vm.form,"label-width":"100px"}},[_c('el-table',{ref:"tableList",staticStyle:{"width":"100%","margin":"15px 0"},attrs:{"data":_vm.form.buildings,"border":"","height":"calc(100vh - 430px)","stripe":true},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"70","align":"center","index":((_vm.searchBox.current-1)*_vm.searchBox.size)+1}}),_c('el-table-column',{attrs:{"prop":"aaa","label":"表编号","align":"center","show-overflow-tooltip":"","render-header":_vm.addRedStar},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"label":"","label-width":"1","prop":'buildings.' + scope.$index + '.sheetSteel',"rules":[
                    { required: true, message: '请输入表编号', trigger: 'blur' },
                    { min: 1, max: 80, message: '长度最多80个字符', trigger: 'blur' }
                  ]}},[_c('el-input',{attrs:{"size":"mini","placeholder":"请输入内容","maxlength":"80"},model:{value:(scope.row.sheetSteel),callback:function ($$v) {_vm.$set(scope.row, "sheetSteel", $$v)},expression:"scope.row.sheetSteel"}})],1)]}}])}),_c('el-table-column',{attrs:{"prop":"addressAttr","label":"燃气表类型","align":"center","show-overflow-tooltip":"","render-header":_vm.addRedStar},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"label":"","label-width":"1","prop":'buildings.' + scope.$index + '.tableType',"rules":[
                    { required: true, message: '请输入表编号', trigger: 'blur' },
                    { min: 1, max: 80, message: '长度最多80个字符', trigger: 'blur' }
                  ]}},[_c('el-select',{attrs:{"placeholder":"请选择","filterable":"","size":"mini"},model:{value:(scope.row.tableType),callback:function ($$v) {_vm.$set(scope.row, "tableType", $$v)},expression:"scope.row.tableType"}},_vm._l((_vm.tableTypeList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.name,"value":item.id}})}),1)],1)]}}])}),_c('el-table-column',{attrs:{"prop":"companyId","label":"表向","size":"mini","align":"center","show-overflow-tooltip":"","render-header":_vm.addRedStar},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"label":"","label-width":"1","prop":'buildings.' + scope.$index + '.tableDirection',"rules":[
                    { required: true, message: '请输入表编号', trigger: 'blur' },
                    { min: 1, max: 80, message: '长度最多80个字符', trigger: 'blur' }
                  ]}},[_c('el-select',{attrs:{"size":"mini","filterable":"","placeholder":"请选择"},model:{value:(scope.row.tableDirection),callback:function ($$v) {_vm.$set(scope.row, "tableDirection", $$v)},expression:"scope.row.tableDirection"}},_vm._l((_vm.tableDirectionList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.name,"value":item.id}})}),1)],1)]}}])}),_c('el-table-column',{attrs:{"prop":"floorType","label":"燃气表厂家","align":"center","show-overflow-tooltip":"","render-header":_vm.addRedStar},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"label":"","label-width":"1","prop":'buildings.' + scope.$index + '.tableVender',"rules":[
                    { required: true, message: '请输入表编号', trigger: 'blur' },
                    { min: 1, max: 80, message: '长度最多80个字符', trigger: 'blur' }
                  ]}},[_c('el-select',{attrs:{"size":"mini","filterable":"","placeholder":"请选择"},model:{value:(scope.row.tableVender),callback:function ($$v) {_vm.$set(scope.row, "tableVender", $$v)},expression:"scope.row.tableVender"}},_vm._l((_vm.tableVenderList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.name,"value":item.id}})}),1)],1)]}}])}),_c('el-table-column',{attrs:{"prop":"addressName","label":"表封号","align":"center","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{attrs:{"size":"mini","placeholder":"请输入内容","maxlength":"80"},model:{value:(scope.row.tableTitles),callback:function ($$v) {_vm.$set(scope.row, "tableTitles", $$v)},expression:"scope.row.tableTitles"}})]}}])}),_c('el-table-column',{attrs:{"prop":"addressName","label":"所接炉具","align":"center","show-overflow-tooltip":"","render-header":_vm.addRedStar},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"label":"","label-width":"1","prop":'buildings.' + scope.$index + '.receiveStove',"rules":[
                    { required: true, message: '请输入表编号', trigger: 'blur' },
                    { min: 1, max: 80, message: '长度最多80个字符', trigger: 'blur' }
                  ]}},[_c('el-input',{attrs:{"size":"mini","placeholder":"请输入内容","maxlength":"80"},model:{value:(scope.row.receiveStove),callback:function ($$v) {_vm.$set(scope.row, "receiveStove", $$v)},expression:"scope.row.receiveStove"}})],1)]}}])}),_c('el-table-column',{attrs:{"prop":"aaa","label":"开户日期","align":"center","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-date-picker',{staticStyle:{"width":"170px"},attrs:{"size":"mini","type":"datetime","value-format":"yyyy-MM-dd HH:mm:ss","placeholder":"选择日期"},model:{value:(scope.row.openDate),callback:function ($$v) {_vm.$set(scope.row, "openDate", $$v)},expression:"scope.row.openDate"}})]}}])}),_c('el-table-column',{attrs:{"prop":"addressName","label":"备注","align":"center","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_c('el-input',{attrs:{"size":"mini","placeholder":"请输入备注","maxlength":"80"},model:{value:(row.remark),callback:function ($$v) {_vm.$set(row, "remark", $$v)},expression:"row.remark"}})]}}])})],1)],1),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("确定")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headline"},[_c('span'),_vm._v("表具信息")])
}]

export { render, staticRenderFns }